body {
  font-family: 'Open Sans', sans-serif;
}

.logo {
  background: url("../assets/svg/blue-logo.svg") 50% 50% no-repeat; 
  width: 180px; 
  height: 80px;
  background-size: contain; 
  margin: 0 auto;
}

.logo-sm {
  background: url("../assets/svg/blue-logo.svg") 50% 50% no-repeat; 
  width: 120px; 
  height: 70px;
  background-size: contain; 
  margin: 0 auto;
}

label {
  font-size: 14px !important;
  margin: 0 !important
}